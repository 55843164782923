@import '@/_styles/import';
.tooltipOpener {
  --tooltip-z-index: 1;

  width: 22px;
  height: 22px;
  background: $info-icon-background;

  &:hover,
  &:active,
  &[data-state*='open'] {
    background-color: $vv-red;
  }
}

.tooltipContent {
  color: $grey-text;
  text-align: left;
  white-space: pre-wrap;
}

.tooltipParagraph {
  display: block;

  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

.optOutButton {
  margin-left: 4px;
  padding: 1px;

  line-height: inherit;
  color: inherit;
  text-decoration: underline;
  vertical-align: baseline;

  background: transparent;
  border: 0;
}
