$white: #fff;
$white-FC: #fcfcfc;
$white-FE: #fefefe;
$white-FB: #fbfbfb;
$white-E9: #e9e9e9;
$white-F5: #edfbf5;
$grey-light: #fafafa;
$gray-light-2: #f8f8f8;
$gray-105: #f1f2f4;
$gray-110: #f0f0f0;
$gray-200: #e9ecef;
$gray-205: #eaeaea;
$gray-207: #e9e5e5;
$gray-210: #e4e4e4;
$gray-216: #d8d8d8;
$gray-223: #dfdfdf;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-410: #b7b7b7;
$gray-420: #a0a0a0;
$gray-505: #949494;
$gray-510: #8c8c8c;
$gray-590: #737685;
$lightgrey2: #6e6e6e;
$gray-600: #6c757d;
$gray-900: #2d3138;
$gray-000: rgb(255 255 255 / 0%);
$gray-005: rgb(255 255 255 / 50%);
$gray-004: rgb(255 255 255 / 40%);
$gray-085: rgb(255 255 255 / 85%);
$gray-050: rgb(224 224 224 / 50%);
$gray-248: rgb(248 248 248 / 0%);
$gray-010: #e0e0e0;
$gray-054: #545454;
$gray-204: rgb(25 25 25 / 40%);
$gray-light: #333;
$grey-001: #dbdfe2;
$grey-text: #6e6e6e;
$grey-bb: #bbb;
$grey-scale: #4a4a4a;
$body-color-black-opacaity: rgb(0 0 0 / 5%);
$blue-003: rgb(217 244 249 / 30%);
$blue-light: #d9f4f9;
$super-silver: #eee;
$neutral-sea-mist: #eaeef1;

$black: #151515;
$black-19: #191919;
$black-deep: #000;
$black_extended: #1a1a1a;
$black_secondary: #1c1c1c;
$black-025: rgb(0 0 0 / 25%);
$black-001: rgb(0 0 0 / 10%);
$black-004: rgb(0 0 0 / 40%);
$black-003: rgb(0 0 0 / 30%);
$black-0004: rgb(0 0 0 / 4%);
$black-005: rgb(25 25 25 / 50%);
$black-008: rgb(0 0 0 / 8%);
$black-006: rgb(0 0 0 / 6%);
$black-012: rgb(0 0 0 / 12%);
$black_25: rgb(25 25 25 / 8%);
$black-violet: #321b63;
$black-violet-95: rgb(50 27 99 / 95%);
$light-green: #d2f4e5;
$light-green1: #c3f1dd;
$light-green2: #e1f9ee;
$brass: #877453;
$silver: #ccc;
$blue-purple: #0046ad;
$blue-purple-dark: #01265c;
$tropicalblue: #74d8ea;
$tropical-blue-2: #71d6e0;
$iron: #d5d6d7;
$lightwhite: #f7f7f7;
$purple: #3c1053;
$purple-02: #513d7a;
$purple-dark: #170620;
$green: #58d6bd;
$pale-green: #f7fdfc;
$blue-60: #d9f4f999;

$brass-cabin: #867453;
$brass-cabin-dark: #443b2a;
$gold: #918065;
$primary-red: red;
$red: #c00;
$red-dark: #760504;
$light-orange: #ffb7ae;
$pale-yellow-orange: #fef2ca;
$light-blue-shade-1: #b6ebf4;
$lead-cabin-color: #e3f3f6;
$yellow-001: rgb(254 243 156 / 10%);
$uplift: #1faa9f;
$uplift-dark: #106059;
$error-dark: #fb9023;

// base font colors
$body-bg: $white;
$body-color: $black;

$info: #d5d6d7;
$success: $tropicalblue;
$error: #be4809;
$error-bg: #fffaf1;
$maroon: #520000;
$pink-dark: #180621;
$yellow-dark: #5e513a;
$black-version2: #111;
$success: #74ead2;
$success-bg: #f1fdfa;
$warning: rgb(255 148 0);
$warning-bg: #fffaf1;

// virgin brand colors
$vv-purple: #2e0445;
$vv-red: #c00;
$vv-gold: #918065;
$vv-purple-light: #4b2770;

$primary: $vv-red;
$secondary: $vv-purple;
$headings-color: $body-color;

// Voyage progress bar
$progress-default: #b5b7b9;
$progress-divider: #d5d6d7;
$progress-complete: $gray-900;

// footer colours {
$vv-footer-grey-divider: #e3e8ea;

$promo-banner-flyout-btn: #e10a0a;
$promo-banner-icon-color: #3b1053;
$promo-separator: #c00;

// Imported fonts
$virgin: 'Voyages Headline';
$centra: 'VVCentra2';
$fallback-fonts: 'Arial', 'Helvetica', sans-serif;

$headings-font-family: $centra;
$font-family-base: $centra, $fallback-fonts;
$font-family-headline: $virgin, $font-family-base;

// for use with mixin
// e.g. font($name: 'display')
$fonts: (
  display: $virgin,
  section: $centra,
  body: $font-family-base,
);

$font-weights: (
  normal: 400,
  bold: 700,
  medium: 500,
);

$h1-font-size: rem(100);
$h2-font-size: rem(50);
$h3-font-size: rem(35);
$h4-font-size: rem(20);
$h5-font-size: rem(12);
$h6-font-size: rem(14);

$heading-sizes: (
  h1: $h1-font-size,
  h2: $h2-font-size,
  h3: $h3-font-size,
  h4: $h4-font-size,
  h5: $h5-font-size,
  h6: $h6-font-size,
);

// Z-indexes. All together to control mutual overlap (ordered by z-index)
$z-index-share-button-modal: 19;
$z-index-sort-dropdown: 19;
$z-index-black-friday-rotunda: 100;
$z-index-sort-refinement-dropdown: 99;
$z-index-search-again: 101;
$z-index-global-navigation--overlapped: 999;
$z-index-chatbot-iframe: 999;
$z-index-global-navigation: 1000;
$z-index-AF-footer: 9999;
$z-index-currency-dropdown: 9999;
$z-index-payment-timer: 10009;
$z-index-f-m-agency: 10010;
$z-index-global-navigation--opened: 10020;
$z-index-chat: 10030;
$z-index-flyout: 10030;
$z-index-tooltip: 10040;
$z-index-spinner: 999999;
$z-index-next-progress-bar: 999999;
$z-mobile-refinement-filter: 100100;
$z-mobile-refinement-filter-buttons: 100500;

// Promo Banner Places:
$promobannerplace-min-size-375: 327px;
$promobannerplace-min-size-431: 412px;
$promobannerplace-min-size-576: 525px;
$promobannerplace-min-size-768: 688px;
$promobannerplace-min-size-992: 804px;
$promobannerplace-min-size-1200: 862px;
$promobannerplace-min-size-1600: 1194px;

$headings-line-height: 0.9;

// Button
$btn-font-weight: 700;
$border-width: 1px;
$border-radius: rem(4);
$btn-font-size-sm: rem(15);
$btn-font-size-md: rem(13);
$btn-font-size-lg: rem(13);

$btn-padding-y-sm: rem(6);
$btn-padding-x-sm: rem(20);
$btn-line-height-lg: 1.6;
$btn-padding-y: rem(10);
$btn-padding-x: rem(30);
$btn-line-height: 2.2;
$btn-padding-y-lg: rem(16);
$btn-padding-x-lg: rem(32);
$btn-line-height-lg: 2.4;

$btn-base-color-disabled: #979ea4;
$btn-base-bg-disabled: rgba($black, 0.08);
$btn-base-border-disabled: $btn-base-bg-disabled;

$btn-base-color-invert: #cfcfcf;
$btn-base-bg-disabled-invert: #f2f4f6;

$btn-suites-color: #b2a898;
$btn-cabins-color: #575757;
$btn-bg-red: #b90101;

// Forms
$form-check-input-margin-y: 0.2rem;
$form-group-margin-bottom: 0;

// Nav
$nav-bar-height: 55px;
$nav-gradient-bg: linear-gradient(to bottom, rgb(0 0 0 / 60%) 0%, rgb(0 0 0 / 0%) 100%);
$nav-semi-trans-bg: rgb(0 0 0 / 80%);

// Override Bootstrap layout
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  // 1140 is our max width per design, for booking use make-container-padded function
  xl: 1440px,
);

$grid-breakpoints: (
  xxs: 0,
  xs: 431px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  3xl: 1920px,
  4xl: 2560px,
);

$grid-gutter-width: 20px;
$container-max-width: map-get($container-max-widths, xl);
$main-nav-height: 55px;
$subnav-height: 54px;
$subnav-height-expanded-desktop: 129px;
$subnav-height-expanded-mobile: 150px;
$total-nav-offset: $main-nav-height + $subnav-height;

$progress-bar-height-mobile: 88px;
$progress-bar-height-desktop: 113px;

// Flyout config
$flyout-width-desktop: 575px;
$flyout-animation-duration: 300ms;
$flyout-payment-width-desktop: 1024px;

// VoyageCredit
$VoyageCredit-background: #a6f1e2;

// WhatsIncluded
$WhatsIncluded-border-color: $gray-205;
$WhatsIncluded-gradient-start-point: #3c1153;
$WhatsIncluded-gradient-end-point: #770930;

//Account
$vv-account-border: #b5b7b9;

// Cabins
$storyblock-background: #f2f2f2;

// Share
$share-input-background: #f3f3f3;
$facebook: #3b599a;
$twitter: #3c96c8;
$email: #9b9b9b;
$share-border: #ebebeb;

// Invite
$invite-background: #f8f8f8;

// Itinerary details
$itinerary-background: #f5f5f5;

// Messaging
$notification: #d5d6d7;

$toast: #373737;

// Form states
$form-feedback-valid-color: #008000;
$form-feedback-invalid-color: #f00;

//BarTabAddOn
$barTab-green-shade-1: #009863;
$barTab-white-extended: #e9eaea;
$flyout-background: #f8f8f8;
$flyout-dashed-border: #dedede;
$barTab-green-shade: #58d6bc;
$purchased-item-shadow-2: rgb(183 183 183 / 25%);
$light-shadow: #0000000d;
$barTab-bar-bckg: #fef6dc;
//urgencyLayer
$urgency-layer-background: #fef2ca;

//override bootstrap layout as of MSH-54659, MSH-54660

$container-xxxs-width: '375px';
$container-xxxs-width-376: '376px';

$container-xxs-width: '428px';
$container-xs-min-width: '427.98px';
$container-xs-max-mobile-width: '430px';
$container-xs-max-width: '575.98px';
$container-xs-width: '576px';
$container-xs-width-577: '577px';

$container-sm-max-width: '767px';
$container-sm-width: '767.98px';
$container-sm-width-768: '768px';
$container-sm-width-769: '769px';

$container-md-width: '991.98px';
$container-md-max-width: '992px';

$container-lg-width-1024: '1024px';

$container-lg1-max-width: '1199.98px';
$container-lg1-width: '1199px';
$container-md1-width: '1180px';
$container-lg1-min-width: '1200px';

$container-lg2-width: '1599px';

// Progressive Summary
$background-color: #f8f8f8;

//Cabin2.0 MetaCategories
$cabin-meta-color2: #c00;
$cabin-meta-color4: #f2f4f6;
$cabin-meta-border: rgb(104 109 114 / 20%);
$rgWhite: rgb(255 255 255);

// Summary Module z-index
$backdrop-index: 10;
$content-index: 11;
$best-rate-text: #5b34b7;
$ghost-white: #f1f1f3;
$best-rate-background: #ccf2eb;
$border-color: #3d2449;

$mordant-red: #b00411;
$chinese-silver: #ccc;
$american-silver: #d0d0d0;
$sunglow-03: rgb(252 212 80 / 30%);
$upliftIconBackground: #d4d4d4;

// Exit modal
$light-black1: rgb(0 0 0 / 9%);
$light-grey: rgb(255 255 255 / 75%);
$light-grey1: rgb(255 255 255 / 10%);

$info-icon-background: #686d71;

// Summary 2.0
$fare-breakdown-tooltip-border: #ebebeb;
$addon-border: $gray-205;
$authentication-block-background: #eaeef1;
$gray-outer: #0000002b;

// Summary 3.0
$details-block-border: #eaeef0;
$details-block-background: #f6f7f8;
$details-block-lockitin-border: #fffce4;
$details-block-lockitin-background: #fffef5;

// High screen resolutions (500dpi = 250% desktop browser zoom)
$zoom-more-than-250: 499dpi;

$coral-cint: #fff1ef;
$lavender-gray: #bdc2cc;
$card-box-shadow: rgb(213 214 215 / 100%);
